import { render, staticRenderFns } from "./dynamicComponents.vue?vue&type=template&id=4186d641&scoped=true"
import script from "./dynamicComponents.vue?vue&type=script&lang=js"
export * from "./dynamicComponents.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4186d641",
  null
  
)

export default component.exports